import request from '@/auth/jwt/useJwt'

/*  查询列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  let requestUrl = `notifier/template/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc`
  let index = 0
  if (params !== null) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.type) {
      requestUrl += `&terms[${index}].column=type&terms[${index}].value=${params.type}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 加载通知类型
export function types() {
  return request.axiosIns({
    url: 'notifier/config/types',
    method: 'get',
  })
}
// 当通知类型是网络组件的时候根据服务商查询该服务商类型下的网络组件
export function getNetworkByTypeId(typeId) {
  return request.axiosIns({
    url: `network/config/_query/no-paging?paging=false&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&terms%5B0%5D.column=type&terms%5B0%5D.value=${typeId}`,
    method: 'get',
  })
}
// 新增
export function add(data) {
  return request.axiosIns({
    url: 'notifier/template',
    method: 'PATCH',
    data,
  })
}
// 删除
export function remove(id) {
  return request.axiosIns({
    url: `notifier/template/${id}`,
    method: 'DELETE',
  })
}
// 查询调试模板
export function getConfig(type, provider) {
  return request.axiosIns({
    url: `notifier/config/_query?paging=false&terms%5B0%5D.column=type&terms%5B0%5D.value=${type}&terms%5B1%5D.column=provider&terms%5B1%5D.value=${provider}`,
    method: 'get',
  })
}
// 发送测试
export function send(configId, data) {
  return request.axiosIns({
    url: `notifier/${configId}/_send`,
    method: 'post',
    data,
  })
}
