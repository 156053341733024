<template>
  <validation-observer ref="refFormObserver">
    <b-form class="p-2">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">模板名称：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="模板名称"
              rules="required"
            >
              <b-form-input
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入模板名称"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">模板名称不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">通知类型：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="通知类型"
              rules="required"
            >
              <v-select
                v-model="typeVal"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                placeholder="通知类型"
                label="name"
                :disabled="isView"
                :reduce="option => option.id">
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">请选择通知类型</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">服务商：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="服务商"
              rules="required"
            >
              <v-select
                v-model="providerVal"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="providersList"
                placeholder="服务商"
                label="name"
                :disabled="isView"
                :reduce="option => option.id">
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">请选择服务商</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'voice'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">模版ID：</span>
            </label>
            <validation-provider
              name="模版ID"
            >
              <b-form-input
                v-model="formData.template.ttsCode"
                placeholder="模版ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">被叫显号：</span>
            </label>
            <validation-provider
              name="被叫显号"
            >
              <b-form-input
                v-model="formData.template.calledShowNumbers"
                placeholder="请输入被叫显号"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">被叫号码：</span>
            </label>
            <validation-provider
              name="被叫号码"
            >
              <b-form-input
                v-model="formData.template.CalledNumber"
                placeholder="请输入被叫号码"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">播放次数：</span>
            </label>
            <validation-provider
              name="播放次数"
            >
              <b-form-input
                type="number"
                v-model="formData.template.PlayTimes"
                placeholder="请输入播放次数"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'email'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">标题：</span>
            </label>
            <validation-provider
              name="标题"
            >
              <b-form-input
                v-model="formData.template.subject"
                placeholder="请输入标题"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">收件人：</span>
            </label>
            <validation-provider
              name="收件人"
            >
              <b-form-textarea
                v-model="formData.template.sendTo"
                v-b-tooltip.hover.top="'多个收件人以 , 分隔'"
                placeholder="请输入收件人"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">附件：</span>
            </label>
            <validation-provider
              name="附件"
            >
              <b-form-input
                v-model="formData.template.location"
                placeholder="请输入附件"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <!-- <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">正文：</span>
            </label>
            <validation-provider
              name="正文"
            >
              <b-form-input
                v-model="formData.template.emailEditor"
                placeholder="请输入正文"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group> -->
          <froala-editor :value="formData.template.text" @blur="editBlur"/>
        </b-col>
      </b-row>
      <b-row v-if="providerVal === 'corpMessage'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">应用ID：</span>
            </label>
            <validation-provider
              name="应用ID"
            >
              <b-form-input
                v-model="formData.template.agentId"
                placeholder="请输入应用ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">收信人ID：</span>
            </label>
            <validation-provider
              name="收信人ID"
            >
              <b-form-input
                v-model="formData.template.toUser"
                placeholder="请输入收信人ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">收信部门ID：</span>
            </label>
            <validation-provider
              name="收信部门ID"
            >
              <b-form-input
                v-model="formData.template.toParty"
                placeholder="请输入收信部门ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">按标签推送：</span>
            </label>
            <validation-provider
              name="按标签推送"
            >
              <b-form-input
                v-model="formData.template.toTag"
                placeholder="请输入按标签推送"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">内容：</span>
            </label>
            <validation-provider
              name="内容"
              #default="{ errors }"
              rules="required"
            >
              <b-form-textarea
                v-model="formData.template.message"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入内容"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">内容不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="providerVal === 'officialMessage'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">模版ID：</span>
            </label>
            <validation-provider
              name="模版ID"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="formData.template.wxTemplateId"
                placeholder="请输入模版ID"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">模版ID不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">接收人：</span>
            </label>
            <validation-provider
              name="接收人"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="formData.template.touser"
                placeholder="请输入接收人"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">接收人不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'dingTalk'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">应用ID：</span>
            </label>
            <validation-provider
              name="应用ID"
            >
              <b-form-input
                v-model="formData.template.agentId"
                placeholder="请输入应用ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">收信人ID：</span>
            </label>
            <validation-provider
              name="收信人ID"
            >
              <b-form-input
                v-model="formData.template.userIdList"
                placeholder="请输入收信人ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">收信部门ID：</span>
            </label>
            <validation-provider
              name="收信部门ID"
            >
              <b-form-input
                v-model="formData.template.departmentIdList"
                placeholder="请输入收信部门ID"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">全部用户：</span>
            </label>
            <validation-provider
              name="按标签推送"
            >
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="formData.template.toAllUser"
                  name="some-radio9"
                  value="true"
                  class="custom-control-primary"
                >
                  是
                </b-form-radio>
                <b-form-radio
                  v-model="formData.template.toAllUser"
                  name="some-radio9"
                  value="false"
                  class="custom-control-secondary"
                >
                  否
                </b-form-radio>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">内容：</span>
            </label>
            <validation-provider
              name="内容"
              #default="{ errors }"
              rules="required"
            >
              <b-form-textarea
                v-model="formData.template.message"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入内容"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">内容不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'network'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="font-medium-1 x-text-bold text-body">消息：</span>
            </label>
            <validation-provider
              name="消息"
            >
              <b-form-textarea
                v-model="formData.template.text"
                placeholder="请输入消息"
                :readonly="isView"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'sms' && providerVal === 'test'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">短信内容：</span>
            </label>
            <validation-provider
              name="短信内容"
              #default="{ errors }"
              rules="required"
            >
              <b-form-textarea
                v-model="formData.template.text"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入短信内容"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">内容不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">收件人：</span>
            </label>
            <validation-provider
              name="收件人"
              #default="{ errors }"
              rules="required"
            >
              <b-form-textarea
                v-model="formData.template.sendTo"
                v-b-tooltip.hover.top="'多个收件人以 , 分隔'"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入收件人"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">收件人不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typeVal === 'sms' && providerVal === 'aliyunSms'">
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">模板编码：</span>
            </label>
            <validation-provider
              name="短信内容"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="formData.template.code"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入阿里云短信模板编码"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">短信内容不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">签名：</span>
            </label>
            <validation-provider
              name="签名"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="formData.template.signName"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入阿里云短信模板签名"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">签名不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">收件人：</span>
            </label>
            <validation-provider
              name="收件人"
              #default="{ errors }"
              rules="required"
            >
              <b-form-input
                v-model="formData.template.phoneNumber"
                :state="errors.length > 0 ? false:null"
                v-b-tooltip.hover.top="'暂只支持单个联系人'"
                placeholder="请输入收件人"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">收件人不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-col
        v-if="!isView"
        cols="12"
        class="mt-1"
      >
        <b-button
          variant="primary"
          type="submit"
          @click.prevent="validationForm"
        >
          确定
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormTextarea, BFormRadio, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import {
  getNetworkByTypeId, add,
} from '@/api/system/notice/template'
import froalaEditor from '@core/components/cx/emailEditor/froalaEditor.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormRadio,
    vSelect,
    froalaEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 网络组件列表
      networkList: [],
      providerVal: undefined,
      typeVal: undefined,
      providersList: [],
      formData: {
        id: undefined,
        name: undefined,
        type: undefined,
        provider: undefined,
        template: {
        },
      },
      required,
    }
  },
  created() {
  },
  watch: {
    visible(val) {
      if (val === true) {
        if (this.isNew === false) {
          this.typeVal = this.template.type
          this.providerVal = this.template.provider
          this.formData = this.template
        } else {
          this.$refs.refFormObserver.reset()
          this.formData = {
            id: undefined,
            name: undefined,
            type: undefined,
            provider: undefined,
            template: {
            },
          }
          this.typeVal = undefined
          this.providerVal = undefined
        }
      }
    },
    typeVal(val) {
      this.types.forEach(type => {
        if (val === type.id) {
          let providers = []
          type.providerInfos.forEach(provider => {
            let proObj = {
              name: provider.name,
              id: provider.id,
            }
            providers.push(proObj)
          })
          this.providersList = providers
        }
      })
      this.formData.type = val
    },
    providerVal(val) {
      if (this.typeVal === 'network') {
        getNetworkByTypeId(val).then(res => {
          this.networkList = res.data.result
        })
      }
      this.formData.provider = val
    },
  },
  methods: {
    editBlur(value) {
      this.formData.template.text = value
    },
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          if (this.providerVal === 'officialMessage') {
            this.formData.template.configId = 'ignore'
          }
          this.formData.template = JSON.stringify(this.formData.template)
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功！')
            this.$emit('editSuccess')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
