<template>
  <div>
    <x-table
      title="通知模板"
      ref="templateTable"
      :card="true"
      tableType="list"
      :options="options"
      :hideSearchBar="false"
      @search="searchHandle"
      @rowDownLoad="rowDownLoad"
      @rowDebug="rowDebug"
      @rowDelete="rowDelete">
      <template slot="actions">
        <b-button
          v-ripple.400="$x.ripple.white"
          variant="primary"
          @click="visibleFlag = true, isNew = true">
          <feather-icon
            size="16"
            icon="PlusIcon"
            class="mr-50"
          />
          新增
        </b-button>
        <b-button
          v-ripple.400="$x.ripple.white"
          class="ml-2"
          variant="primary"
          @click="exportAll()">
          <feather-icon
            size="16"
            icon="ArrowUpRightIcon"
            class="mr-50"
          />
          导出
        </b-button>
        <el-upload
          class="upload-demo"
          :action="action"
          :headers="headers"
          accept=".json"
          :show-file-list="false"
          :limit="1"
          :on-success="(response) => importSuccess(response)">
          <b-button v-ripple.400="$x.ripple.white"
                    class="ml-2"
                    variant="primary">
            <feather-icon
              size="16"
              icon="ArrowDownLeftIcon"
              class="mr-50"
            />
            导入
          </b-button>
        </el-upload>
      </template>
      <template slot="row-view" slot-scope="scope">
          <b-button
            v-ripple.400="$x.ripple.primary"
            class="btn-icon rounded-circle"
            variant="flat-primary"
            @click="toView(scope.row)">
            <feather-icon icon="EyeIcon" />
          </b-button>
      </template>
      <template slot="row-edit" slot-scope="scope">
        <b-button
          v-ripple.400="$x.ripple.primary"
          class="btn-icon rounded-circle"
          variant="flat-primary"
          @click="toEdit(scope.row)">
          <feather-icon icon="EditIcon" />
        </b-button>
      </template>
      <template slot="provider" slot-scope="scope">
        <b-badge variant="light-success">
          {{getProvider(scope.row.type,scope.row.provider)}}
        </b-badge>
      </template>
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="820px"
      :visible="visibleFlag"
      @hidden="hideForm"
    >
      <template-form
        :isNew="isNew"
        :isView="isView"
        :template="template"
        :visible="visibleFlag"
        :types="types"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
    <b-modal
      ref="debugModal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      title="调试"
      size="lg"
      :visible="debugModalFlag"
      @hidden="resetDebugModal"
      @ok="send"
    >
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="通知配置">
          <v-select
            v-model="configId"
            :options="configList"
            label="name"
            placeholder="请选择配置"
            :reduce=" option => option.id"
          >
            <template slot="no-options">
              {{ $t('noData') }}
            </template>
          </v-select>
        </x-form-feild>
        <x-form-feild label="变量">
          <b-form-textarea
            v-model="context"
            placeholder='请输入变量 如：{ "aa" ： "aa"}'
          />
        </x-form-feild>
      </x-form-validator>
    </b-modal>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BButton, BSidebar, BModal, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  query, types, remove, getConfig, send,
} from '@/api/system/notice/template'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import { getToken } from '@/@core/auth/token'
import templateForm from './templateForm.vue'

export default {
  components: {
    templateForm,
    XTable,
    BButton,
    BSidebar,
    BModal,
    BFormTextarea,
    vSelect,
    XFormValidator,
    XFormFeild,
    BBadge,
  },
  data() {
    return {
      // 调试参数
      context: '',
      // 调试模板
      configId: undefined,
      // 调试模板选择数组
      configList: [],
      debugModalFlag: false,
      // 上传所需参数
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      recordId: undefined,
      isView: false,
      isNew: false,
      // sidebar显隐控制参数
      visibleFlag: false,
      template: undefined,
      types: [],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon' },
          { action: 'debug', name: '调试', icon: 'SkipForwardIcon' },
          { action: 'downLoad', name: '下载', icon: 'DownloadIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        actionFilter: action => action === this.$x.biz.FormType.VIEW || action === this.$x.biz.FormType.EDIT || action === 'delete' || action === 'downLoad' || action === 'record' || action === 'debug',
        columns: [{
          label: 'id',
          rowShow: false,
          prop: 'id',
          searchShow: false,
          editShow: false,
          addShow: false,
          rules: {
            rule: 'required',
            message: '请输入id',
          },
        }, {
          label: '模板名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入配置名称',
          },
        }, {
          label: '通知类型',
          labelShow: true,
          prop: 'type',
          onDictSelected: (option, columns, formData) => {
            this.types.forEach(type => {
              if (option === type.id) {
                let providers = []
                type.providerInfos.forEach(provider => {
                  let proObj = {
                    label: provider.name,
                    value: provider.id,
                  }
                  providers.push(proObj)
                })
                columns[3].dictData = providers
                formData.provider = undefined
              }
            })
          },
          type: 'select',
          dictUrl: '/notifier/config/types',
          rowSelect: true,
          selectVariant: () => 'light-primary',
          // getDictLabel: option => option.label + `（${option.value})`,
          props: {
            label: 'name',
            value: 'id',
          },
          rules: {
            rule: 'required',
            message: '请选择通知类型',
          },
        }, {
          label: '服务商',
          labelShow: true,
          prop: 'provider',
          type: 'select',
          dictData: [],
          searchShow: false,
          // getDictLabel: option => option.label + `（${option.value})`,
          rules: {
            rule: 'required',
            message: '请选择服务商',
          },
        },
        ],
      },
    }
  },
  created() {
    types().then(resp => {
      this.types = resp.data.result
    })
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    getProvider(type, provider) {
      for (let t of this.types) {
        if (t.id === type) {
          for (let p of t.providerInfos) {
            if (p.id === provider) {
              return p.name
            }
          }
        }
      }
      return ''
    },
    hideForm() {
      this.visibleFlag = false
      this.template = undefined
      this.isNew = false
      this.isView = false
    },
    editSuccess() {
      this.hideForm()
      this.$refs.templateTable.__searchHandle()
    },
    toView(row) {
      let obj = this._.cloneDeep(row)
      this.isNew = false
      this.isView = true
      this.visibleFlag = true
      const temp = JSON.parse(obj.template)
      this.template = obj
      this.template.template = temp
    },
    toEdit(row) {
      let obj = this._.cloneDeep(row)
      this.isNew = false
      this.isView = false
      this.visibleFlag = true
      const temp = JSON.parse(obj.template)
      this.template = obj
      this.template.template = temp
    },
    rowDownLoad(data, done) {
      // 创建隐藏的可下载链接
      const eleLink = document.createElement('a')
      eleLink.download = `模板配置-${data.name || new Date().format('YYYY/MM/DD HH:mm:ss')}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(data)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
      done()
    },
    exportAll() {
      // 创建隐藏的可下载链接
      const eleLink = document.createElement('a')
      const date = new Date().cxFormat('YYYY/MM/DD HH:mm:ss')
      eleLink.download = `模板配置-${date || '0000'}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(this.$refs.templateTable.pageDatas)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    rowDebug(data) {
      this.template = data
      getConfig(data.type, data.provider).then(resp => {
        this.configList = resp.data.result.data
        this.debugModalFlag = true
      })
    },
    send(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.configId) {
        this.$xtoast.warning('请选择配置信息！')
        return
      }
      let obj = {
        context: this.context ? this.context : {},
        template: this.template,
      }
      send(this.configId, obj).then(resp => {
        if (resp.status === 200) {
          this.$xtoast.success('发送成功')
          this.resetDebugModal()
        }
      })
    },
    resetDebugModal() {
      this.debugModalFlag = false
      this.configId = undefined
      this.template = undefined
      this.context = ''
      this.configList = []
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
